import React, { useState } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Container, Row, Col } from 'react-bootstrap';
import items from './api/items';
import Product from './components/Product/Product';
import Cart from './components/Cart/Cart';
import CheckoutForm from './components/CheckoutForm/CheckoutForm';
import './App.css';

export default function App() {
  const [itemsInCart, setItemsInCart] = useState([]);
  const [finished, setFinished] = useState(false);

  const handleAddToCartClick = async id => {
    setItemsInCart(itemsInCart => {
      const itemInCart = itemsInCart.find(item => item.id === id);

      // if item is already in cart, update the quantity
      if (itemInCart) {
        return itemsInCart.map(item => {
          if (item.id !== id) return item;
          return { ...itemInCart, quantity: item.quantity + 1 };
        });
      }

      // otherwise, add new item to cart
      const item = items.find(item => item.id === id);
      return [...itemsInCart, { ...item, quantity: 1 }];
    });
  };

  const handleQuantityChange = async (id, quantity) => {
    setItemsInCart(itemsInCart => {
      const itemInCart = itemsInCart.find(item => item.id === id);

      // if item is already in cart, update the quantity
      if (itemInCart) {
        return itemsInCart.map(item => {
          if (item.id !== id) return item;
          return { ...itemInCart, quantity };
        });
      }
      return itemsInCart;
    });
  };

  const currency = localStorage.getItem('currency');
  const totalCost = itemsInCart.reduce((acc, item) => {
    let price = item.price;
    if (currency === 'cad') price = 65;
    if (currency === 'usd') price = 50;
    if (currency === 'egp') price = 450;
    return acc + price * item.quantity;
  }, 0);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header-text">
          <h1>Algorithmic Perfumery</h1>
        </div>
      </header>
      <main className="App-shop">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              {!itemsInCart.length ? (
                <div className="App-products">
                  {items.map(item => (
                    <Product
                      key={item.title}
                      title={item.title}
                      price={item.price}
                      onAddToCartClick={() => handleAddToCartClick(item.id)}
                    />
                  ))}
                </div>
              ) : (
                <>
                  {!finished && (
                    <Cart
                      itemsInCart={itemsInCart}
                      changeQuantity={handleQuantityChange}
                      totalCost={totalCost}
                    />
                  )}

                  <Elements>
                    <StripeProvider
                      apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                    >
                      <Elements>
                        <CheckoutForm
                          onFinish={() => setFinished(true)}
                          itemsInCart={itemsInCart}
                        />
                      </Elements>
                    </StripeProvider>
                  </Elements>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
