import React from 'react';
import { Form } from 'react-bootstrap';
import './CartItem.css';

export default function CartItem({
  allowQuantityChange,
  id,
  title,
  cost,
  change,
  quantity,
}) {
  const currency = localStorage.getItem('currency');
  let currencySign = 'CAD$';
  if (currency === 'eur') currencySign = '€';
  if (currency === 'usd') currencySign = '$';
  if (currency === 'egp') currencySign = 'E£';

  return (
    <div className="CartItem">
      <div>{title}</div>
      <div className="CartItem-details">
        <div className="CartItem-quantity">
          Qty:{' '}
          {allowQuantityChange ? (
            <Form.Group>
              <Form.Group controlId="fragranceId">
                <Form.Control
                  as="select"
                  value={quantity}
                  onChange={({ target }) => change(id, target.value)}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </Form.Control>
              </Form.Group>
            </Form.Group>
          ) : (
            quantity
          )}
        </div>
        <div>
          {currencySign}
          {cost.toFixed(2)}
        </div>
      </div>
    </div>
  );
}
