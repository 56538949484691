import React from 'react';

import CartItem from './CartItem/CartItem';
import './Cart.css';

export default function Cart({ changeQuantity, itemsInCart, totalCost }) {
  const currency = localStorage.getItem('currency');
  const country = localStorage.getItem('country');
  let currencySign = 'CAD$';
  if (currency === 'eur') currencySign = '€';
  if (currency === 'usd') currencySign = '$';
  if (currency === 'egp') currencySign = 'E£';

  console.log(currencySign, currency)

  let shipping = 21;
  if (currency === 'eur') shipping = 15;
  if (country === 'NL') shipping = 8;
  if (country === 'EG') shipping = 0;

  return (
    <div className="Cart">
      <h2 className="Cart-title">Your shopping cart</h2>
      {itemsInCart.length ? (
        <div>
          {itemsInCart.map(item => {
            let price = item.price;
            if (currency === 'cad') price = 65;
            if (currency === 'usd') price = 50;
            if (currency === 'egp') price = 450;
            return (
              <CartItem
                key={item.id}
                id={item.id}
                title={item.title}
                cost={price * item.quantity}
                quantity={item.quantity}
                change={changeQuantity}
                allowQuantityChange
              />
            );
          })}
          <CartItem title={shipping === 0 ? "On location pickup" : "Worldwide shipping"} cost={shipping} quantity={1} />
          <div className="Cart-total-cost">
            Total cost: {currencySign}
            {(totalCost + shipping).toFixed(2)}
          </div>
        </div>
      ) : (
        <div>Your cart is empty</div>
      )}
    </div>
  );
}
