import React, { useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Form, Row, Col } from 'react-bootstrap';
import countryList from 'react-select-country-list';

import './CheckoutForm.css';

function CheckoutForm({ onFinish, stripe, itemsInCart }) {
  const country = countryList()
    .getData()
    .filter(({ value }) => value === localStorage.getItem('country'));

  const [status, setStatus] = useState('default');
  const [details, setDetail] = useState({
    name: '',
    email: '',
    country: country[0].label,
  });

  const countryCode = country[0].value;

  let currency = 'eur';
  if (countryCode === 'CA') currency = 'cad';
  if (countryCode === 'EG') currency = 'egp';
  if (countryCode === 'US') currency = 'usd';
  localStorage.setItem('currency', currency);

  let freeShipping = false;
  let pickUpLocation = null;
  if (countryCode === 'EG') {
    freeShipping = true;
    pickUpLocation =
      'Venue: Tahrir Cultural Center (AUC Downtown Campus), enter through Mohamed Mahmoud st., opposite Pizza Hut.';
  }

  const submit = async e => {
    e.preventDefault();

    setStatus('submitting');

    try {
      const response = await fetch('/.netlify/functions/createIntent', {
        method: 'POST',
        body: JSON.stringify({
          metadata: {
            ...details,
          },
          currency,
          items: itemsInCart,
        }),
      });
      const curPaymentIntent = await response.json();
      console.log(curPaymentIntent);
      const shipping = {
        name: details.name,
        address: {
          line1: details.address_line1,
          city: details.city,
          country: country[0].value,
          line2: details.address_line2,
          postal_code: details.address_zip,
          state: details.address_state,
        },
      };

      const { paymentIntent, error } = await stripe.handleCardPayment(
        curPaymentIntent.client_secret,
        {
          payment_method_data: {
            billing_details: {
              name: details.name,
              address: freeShipping ? null : shipping.address,
              email: details.email,
            },
            metadata: details,
          },
          shipping: freeShipping ? null : shipping,
          receipt_email: details.email,
        }
      );
      if (error) {
        setStatus(error.message);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setStatus('complete');
        onFinish(true);
        await fetch('/.netlify/functions/finishIntent', {
          method: 'POST',
          body: JSON.stringify({
            email: details.email,
            id: paymentIntent.id,
          }),
        });
      } else {
        throw new Error('Response was not ok.');
      }
    } catch (err) {
      console.log(err);
      setStatus('error');
    }
  };

  const change = ({ target: { value } }, param) => {
    setDetail({ ...details, [param]: value });
  };

  if (status === 'complete') {
    return (
      <div className="CheckoutForm-complete">
        Payment successful! You will receive a confirmation email shortly. If
        you have any questions, don't hesitate to contact us:{' '}
        <a href="mailto:info@algorithmicperfumery.com">
          info@algorithmicperfumery.com
        </a>
      </div>
    );
  }

  return (
    <Form onSubmit={submit}>
      {pickUpLocation && (
        <div>
          <Form.Group>
            <Form.Group controlId="pickUpLocation">
              <Form.Label className="labelPrimary">Pickup Location</Form.Label>
              <Form.Text className="text-muted pickUpLocation">
                {pickUpLocation}
              </Form.Text>
            </Form.Group>
          </Form.Group>
        </div>
      )}

      <div>
        <Form.Group>
          <Form.Group controlId="fragranceId">
            <Form.Label className="labelPrimary">
              What is the ID of your fragrance? It's printed on the label with
              an "F"
            </Form.Label>
            <Form.Control
              onChange={e => change(e, 'fragranceId')}
              placeholder="E.g. F12345"
              value={details.fragranceId}
              required
            />
            <Form.Text className="text-muted">
              If you can't find it anymore, you can also write down the name and
              we will look it up for you.
            </Form.Text>
          </Form.Group>
        </Form.Group>
      </div>

      <br />

      <Form.Group as={Row} controlId="name">
        <Form.Label column sm="3">
          Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            onChange={e => change(e, 'name')}
            placeholder="Enter your name"
            value={details.name}
            required
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formBasicEmail">
        <Form.Label column sm="3">
          Email address
        </Form.Label>
        <Col sm="9">
          <Form.Control
            onChange={e => change(e, 'email')}
            type="email"
            placeholder="Enter email"
            value={details.email}
            required
          />
          <Form.Text className="text-muted">
            Please use the same address as at the exibition. We'll never share
            your email with anyone else.
          </Form.Text>
        </Col>
      </Form.Group>

      {!freeShipping && (
        <>
          <Form.Group as={Row} controlId="address_line1">
            <Form.Label column sm="3">
              Address Line 1
            </Form.Label>
            <Col sm="9">
              <Form.Control
                onChange={e => change(e, 'address_line1')}
                value={details.address_line1}
                required
              />
              <Form.Text className="text-muted">
                Street address, P.O. box, company name, c/o
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="address_line2">
            <Form.Label column sm="3">
              Address Line 2
            </Form.Label>
            <Col sm="9">
              <Form.Control
                onChange={e => change(e, 'address_line2')}
                value={details.address_line2}
              />
              <Form.Text className="text-muted">
                Apartment, suite , unit, building, floor, etc.
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="address_city">
            <Form.Label column sm="3">
              City or town
            </Form.Label>
            <Col sm="9">
              <Form.Control
                onChange={e => change(e, 'address_city')}
                value={details.address_city}
                required
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="address_state">
            <Form.Label column sm="3">
              State / Province / Region
            </Form.Label>
            <Col sm="9">
              <Form.Control
                onChange={e => change(e, 'address_state')}
                value={details.address_state}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="address_zip">
            <Form.Label column sm="3">
              Zip / Postal Code
            </Form.Label>
            <Col sm="9">
              <Form.Control
                onChange={e => change(e, 'address_zip')}
                value={details.address_zip}
                required
              />
            </Col>
          </Form.Group>
        </>
      )}

      <Form.Group as={Row} controlId="address_country">
        <Form.Label column sm="3">
          Country
        </Form.Label>
        <Col sm="9">
          <Form.Control disabled value={country[0].label} required />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="coupon">
        <Form.Label column sm="3">
          Coupon code
        </Form.Label>
        <Col sm="9">
          <Form.Control
            onChange={e => change(e, 'coupon')}
            value={details.coupon}
          />

          <Form.Text className="text-muted">
            If you have a coupon, you can add it here.
          </Form.Text>
        </Col>
      </Form.Group>
      <br />
      <Form.Group as={Row} controlId="notes">
        <Form.Label column sm="3">
          Notes
        </Form.Label>
        <Col sm="9">
          <Form.Control
            onChange={e => change(e, 'notes')}
            value={details.notes}
            as="textarea"
          />

          <Form.Text className="text-muted">
            If you have additional comments or instructions, you can add them
            here
          </Form.Text>
        </Col>
      </Form.Group>
      <br />
      <h4 className="labelPrimary">Creditcard details</h4>
      <Form.Text className="text-muted">
        We use{' '}
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>{' '}
        for securely processing your payment
      </Form.Text>
      <CardElement hidePostalCode style={{ base: { color: '#54fe05' } }} />
      <button
        className="Product-buy-button"
        variant="primary"
        type="submit"
        disabled={status === 'submitting'}
      >
        {status === 'submitting' ? 'Submitting' : 'Place Order'}
      </button>

      {!['default', 'submitting'].includes(status) && (
        <div className="CheckoutForm-error">{status}</div>
      )}
    </Form>
  );
}

export default injectStripe(CheckoutForm);
