import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import countryList from 'react-select-country-list';

import './Product.css';

const countries = countryList()
  .getData()
  .filter(country => {
    return [
      'AT',
      'BE',
      'CA',
      'CZ',
      'DE',
      'DE',
      'DK',
      'EG',
      'ES',
      'ES',
      'FI',
      'FR',
      'GB',
      'IE',
      'IT',
      'LU',
      'NL',
      'NO',
      'SE',
      'SK',
      'US',
    ].includes(country.value);
  });

export default function Product({ onAddToCartClick, price, title }) {
  const [values, setValue] = useState({});

  const change = ({ target: { value } }, param) => {
    setValue({ ...values, [param]: value });
  };

  if (values.country) {
    const countryCode = values.country;

    let currency = 'eur';
    if (countryCode === 'CA') currency = 'cad';
    if (countryCode === 'US') currency = 'usd';
    if (countryCode === 'EG') currency = 'egp';
    localStorage.setItem('currency', currency);
  }
  localStorage.setItem('country', values.country);

  return (
    <div className="Product">
      <div style={{ maxWidth: `300px`, margin: '0 auto' }}>
        <p className="Product-price">
          Welcome to the Algorithmic Perfumery store. To purchase your creation,
          continue below
        </p>
        <Form.Group>
          <Form.Group controlId="country">
            {/* <Form.Label className="labelPrimary" style={{ fontSize: '1em' }}>
              Please select your country
            </Form.Label> */}
            <Form.Control as="select" onChange={e => change(e, 'country')}>
              <option value="">Select your country</option>
              {countries.map(country => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Group>
        <button
          className="Product-buy-button"
          disabled={!values.country}
          onClick={onAddToCartClick}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
